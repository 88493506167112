<template>
  <div class="serveInfoIndent">
    <el-card class="box-card">
      <!-- 我的订单 -->
      <div class="contents">
        <div class="head">
          <div class="title">
            <el-tabs v-model="titleName" @tab-click="handleTitleClick">
              <el-tab-pane label="服务订单" name="1"></el-tab-pane>
              <el-tab-pane label="需求订单" name="2"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="date_check">
            <span style="font-size: 14px; font-weight: bold; color: #666">订单时间：</span>
            <el-date-picker v-model="value1" size="mini" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <el-button type="danger" style="margin-left: 20px" size="mini" round plain>查询</el-button>
          </div>
        </div>
        <div class="main" style="padding-bottom: 10px">
          <!-- 服务订单 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in labelList" :key="index" :label="item.label" :name="item.name">
              <!-- 数据表格 -->
              <div class v-if="tableData.length!=0">
                <TableOne v-if="item.name !== '6'" :tableData="tableData"></TableOne>
                <TableTwo v-else :tableData="tableData2"></TableTwo>
              </div>
              <!-- 无数据显示 -->
              <div class="no_data" v-else>
                <div class="no_data_con">
                  <img src="@/assets/imgs/svg/no_data.svg" alt />
                  <p style="text-align: center; color: #999">暂无数据~</p>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <!-- 分页 -->
          <div class="block" style="text-align: right; margin-top: 15px">
            <el-pagination @current-change="handleCurrentChange" :current-page="1" background :page-size="10" layout="total, prev, pager, next" :total="100"></el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import TableOne from "@/components/OrderTab/TableOne";
import TableTwo from "@/components/OrderTab/TableTwo";
export default {
  data() {
    return {
      titleName: "1",
      activeName: "1",
      activeIndex: "1",
      id: "", // 用户id
      value1: "",
      labelList: [
        {
          label: "待支付",
          name: "1",
        },
        {
          label: "待确认",
          name: "2",
        },
        {
          label: "工作中",
          name: "3",
        },
        {
          label: "待验收",
          name: "4",
        },
        {
          label: "已完成",
          name: "5",
        },
        {
          label: "已评价",
          name: "6",
        },
      ],
      tableData: [
        {
          service_category: "美妆饰品",
          service_contentg: "天猫",
          hirer: "Lils",
          start_data: "2023-6-27",
          end_data: "2023-7-27",
          amount: 11000,
          state: -2,
        },
        {
          service_category: "美妆饰品",
          service_contentg: "淘宝",
          hirer: "Bills",
          start_data: "2023-6-27",
          end_data: "2023-7-27",
          amount: 18000,
          state: 1,
        },
        {
          service_category: "食品饮料",
          service_contentg: "淘宝",
          hirer: "Alis",
          start_data: "2023-6-27",
          end_data: "2023-7-27",
          amount: 48000,
          state: 3,
        },
      ],
      tableData2: [
        {
          category_detail: "美妆饰品",
          start_data: "2023-6-27",
          hirer: "Bills",
          star_data: 5,
          comment:
            "非常棒，已经长期合作当升科技打开打开了放假啊，阿圣诞节啊刷卡机撒旦撒旦见客户",
        },
        {
          category_detail: "美妆饰品",
          start_data: "2023-6-27",
          hirer: "Lils",
          star_data: 3,
          comment:
            "非常棒，已经长期合作当升科技打开打开了放假啊，阿圣诞节啊刷卡机撒旦撒旦见客户",
        },
        {
          category_detail: "食品饮料",
          start_data: "2023-6-27",
          hirer: "Aills",
          star_data: 1,
          comment:
            "非常棒，已经长期合作当升科技打开打开了放假啊，阿圣诞节啊刷卡机撒旦撒旦见客户",
        },
        {
          category_detail: "日用洗护",
          start_data: "2023-6-27",
          hirer: "Pitter",
          star_data: 4,
          comment:
            "非常棒，已经长期合作当升科技打开打开了放假啊，阿圣诞节啊刷卡机撒旦撒旦见客户",
        },
      ],
    };
  },
  components: {
    TableOne,
    TableTwo,
  },

  methods: {
    handleCurrentChange() {},
    // tabs切换
    handleClick() {},
    // 订单切换
    handleTitleClick() {},
  },
};
</script>
<style scoped lang="less">
/deep/ .active {
  background-color: #fc3737 !important;
}
// 商品
.contents {
  // padding: 10px;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;

    .title {
      font-size: 26px;
      // font-weight: bold;
      /deep/.el-tabs__item {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .main {
    margin-top: 15px;
    // color: #dd6e0c;
    .no_data {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      .no_data_con {
        width: 150px;
        height: 170px;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}
</style>