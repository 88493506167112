<template>
  <div class="tab_con">
    <el-table
      :data="tableData"
      stripe
      border
      :header-cell-style="tableRowClassName"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="category_detail" align="center" label="订单详情">
      </el-table-column>
      <el-table-column prop="start_data" align="center" label="下单时间">
      </el-table-column>
      <el-table-column prop="hirer" align="center" label="评价人">
      </el-table-column>
      <el-table-column prop="star_data" align="center" label="评价星级">
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.star_data"
            disabled
            :colors="['#99A9BF', '#F7BA2A', '#dd6e0c']"
          >
          </el-rate>
        </template>
      </el-table-column>
      <el-table-column
        prop="comment"
        show-overflow-tooltip
        align="center"
        label="评价内容"
      >
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >回复</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 对话框 -->
    <el-dialog
      title="评论"
      custom-class="dias"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="usermess">
        <div class="avater">
          <el-avatar :size="50" icon="el-icon-user-solid"></el-avatar>
        </div>
        <div class="infomess">
          <h4>Username</h4>
          <p style="font-size: 13px">2023-06-29</p>
          <p class="txt">非常棒，已经长期合作，内容可以，积极主动</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-input
          v-model="input"
          placeholder="请输入回复内容"
          style="margin-bottom: 15px"
        ></el-input>
        <el-button
          type="danger"
          size="medium"
          style="margin-left: 30px; width: 100px"
          @click="dialogVisible = false"
          >确 认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      input: "",
    };
  },
  methods: {
    tableRowClassName() {
      return {
        background: "#F1F1F1",
        color: "#555",
      };
    },
    handleClick() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="less">
.tab_con {
  width: 1178px;
}
.el-table .warning-row {
  background: #f1f1f1;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
}
// 用户评论样式
.usermess {
  display: flex;
  .avater {
    margin-right: 15px;
  }
  .infomess {
    h4 {
      margin: 6px 0;
    }
    .txt {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #555;
    }
  }
}

.dialog-footer {
  width: 83%;
  margin: 0 auto;
}
</style>