<template>
  <div class="tab_con">
    <el-table
      :data="tableData"
      stripe
      border
      :header-cell-style="tableRowClassName"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="service_category" align="center" label="服务类目">
      </el-table-column>
      <el-table-column prop="service_contentg" align="center" label="服务内容">
      </el-table-column>
      <el-table-column prop="hirer" align="center" label="雇佣商">
      </el-table-column>
      <el-table-column prop="start_data" align="center" label="开始日期">
      </el-table-column>
      <el-table-column prop="end_data" align="center" label="结束日期">
      </el-table-column>
      <el-table-column prop="amount" align="center" label="金额">
      </el-table-column>
      <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.state === 0">未支付</el-tag>
          <el-tag type="success" v-if="scope.row.state === 1">已支付</el-tag>
          <el-tag type="danger" v-if="scope.row.state === -1">支付失败</el-tag>
          <el-tag type="success" v-if="scope.row.state === 2">正常</el-tag>
          <el-tag type="danger" v-if="scope.row.state === -2">逾期</el-tag>
          <i
            class="el-icon-success"
            v-if="scope.row.state === 3"
            style="color: #209f85; font-size: 20px"
          ></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    tableRowClassName() {
      return {
        background: "#F1F1F1",
        color: "#555",
      };
    },
  },
};
</script>
<style scoped lang="less">
.tab_con {
  width: 1178px;
}
.el-table .warning-row {
  background: #f1f1f1;
}
</style>